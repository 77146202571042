"use client";
import Head from "next/head";
import { useEffect, useMemo } from "react";
import { useEventTracker } from "utils/useEventTracker";
import { useRouter, useSearchParams } from "next/navigation";
import { GetServerSideProps } from "next";
import client from "utils/client";
import { Redirect } from "elements/Redirect";
import { formatRelativeUrl } from "utils/formatRelativeUrl";

const ReferralLink = () => {
	const router = useRouter();
	const searchParams = useSearchParams();

	const trackReferralLinkPageVisit = useEventTracker();

	useEffect(() => {
		trackReferralLinkPageVisit({
			eventName: "",
			data: {
				"Visited From": document.referrer,
			},
		});
		router.push(
			formatRelativeUrl({
				query: { owl: searchParams.get("owl") },
				pathname: "/",
			})
		);
	}, [searchParams.get("owl")]);

	return null;
};

export default ReferralLink;
